import React, { PureComponent, useState, useEffect } from 'react';
import { Box } from '@mui/material'
import { Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

let tickers = ['AAPL','AMZN','GOOG','MSFT','META','NVDA','TSLA', 'TZA', 'SQQQ', 'UDOW', 'TQQQ', 'SDOW', 'SPXL', 'UPRO', 'SPXU', 'SPXS', 'DDM', 'DXD', 'QLD', 'QID', 'YINN', 'YANG'];

const ContentItemElemGraph = (props) => {
    const [isInitialized, setIsInitialized] = useState(false);
    const [dataLeft, setDataLeft] = useState([]);
    const [dataDisplayLeft, setDataDisplayLeft] = useState([]);
    const [dataDisplayRight, setDataDisplayRight] = useState([]);
    const [timer, setTimer] = useState(0);
    const [ticker, setTicker] = useState('');
    const handleTickerChange = (event) => {
      setTicker(event.target.value);
    };
  useEffect(() => {
    const updateData = ( async () => {
      let crudInfo = {
        Action: 'FetchFile',
        List: isInitialized ? 'TradesNew' : 'TradesAll',
        ParentId: props.ParentId ?? '',
        Filters: encodeURIComponent(JSON.stringify([])),
        GlobalFilter: ''
      };

      const json = await window.appContext.transmitter.doCRUD(crudInfo);

      let dataLocal = json.Response.data;
      //console.log(dataLocal);

      if (!isInitialized) {
        if (dataLocal.Seconds != null) {
          setIsInitialized(true);
          window.dataLeft = [];
        }
      }

      if (dataLocal.Seconds != null) {
        dataLocal.Seconds.forEach(secBuckCur => {
          secBuckCur.Syms.forEach(secBuckSymCur => {
            let dataItemCur = window.dataLeft.find(cur => cur.Sym === secBuckSymCur.Sym && cur.Second === secBuckCur.TimeString);
            if (dataItemCur != null) {
              if (secBuckSymCur.PriceLast != null) {
                dataItemCur.PriceLast = secBuckSymCur.PriceLast;
              }
              dataItemCur.Vol = secBuckSymCur.Vol;
            } else {
              dataItemCur = {
                Sym: secBuckSymCur.Sym,
                Second: secBuckCur.TimeString,
                Vol: secBuckSymCur.Vol
              };
              if (secBuckSymCur.PriceLast != null) {
                dataItemCur.PriceLast = secBuckSymCur.PriceLast;
              }
              window.dataLeft.push(dataItemCur);
            }
          });
        });

        window.aggVolSec = {};
        let tickerDataLeft = [];
        let tickerDataRight = [];
        window.dataLeft.forEach((dataCur, dataIndex) => {
          if (dataCur.Sym === ticker) {
            if (window.aggVolSec[ticker] == null) {
              window.aggVolSec[ticker] = 0;
            }
            if (dataCur.Second === '04:00:00' || dataCur.Second === '09:30:00' || dataCur.Second === '16:00:00') {
              window.aggVolSec[ticker] = 0;
            }
            window.aggVolSec[ticker] += parseInt(dataCur.Vol);
            let dataCurNew = {...dataCur};
            dataCurNew.AggVol = window.aggVolSec[ticker];
            tickerDataLeft.push(dataCurNew);
          }
        });

        tickerDataRight = tickerDataLeft.slice(-300);
        setDataDisplayLeft([...tickerDataLeft]);
        setDataDisplayRight([...tickerDataRight]);
      }

      let x = 9;
      return dataLocal;
    });
    updateData();

    let timer1 = setTimeout(() => setTimer((prevtime) => prevtime + (isInitialized ? 2000 : 100000)), (isInitialized ? 2000 : 100000));
    return () => {
      clearTimeout(timer1);
    };        
  }, [timer, ticker]);
  return (
    <Box>
      <div>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120, marginLeft: 10, marginTop: 5 }}>
          <InputLabel id="simple-select-ticker-label">Ticker Symbol</InputLabel>
          <Select
            labelId="simple-select-ticker-label"
            id="simple-select-ticker"
            value={ticker}
            onChange={handleTickerChange}
            label="Ticker Symbol"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={'SPXL'}>SPXL</MenuItem>
            <MenuItem value={'UPRO'}>UPRO</MenuItem>
            <MenuItem value={'SPXU'}>SPXU</MenuItem>
            <MenuItem value={'SPXS'}>SPXS</MenuItem>
            <MenuItem value={'UDOW'}>UDOW</MenuItem>
            <MenuItem value={'DDM'}>DDM</MenuItem>
            <MenuItem value={'SDOW'}>SDOW</MenuItem>
            <MenuItem value={'DXD'}>DXD</MenuItem>
            <MenuItem value={'TQQQ'}>TQQQ</MenuItem>
            <MenuItem value={'QLD'}>QLD</MenuItem>
            <MenuItem value={'SQQQ'}>SQQQ</MenuItem>
            <MenuItem value={'QID'}>QID</MenuItem>
            <MenuItem value={'YINN'}>YINN</MenuItem>
            <MenuItem value={'YANG'}>YANG</MenuItem>
            <MenuItem value={'AAPL'}>AAPL</MenuItem>
            <MenuItem value={'AMZN'}>AMZN</MenuItem>
            <MenuItem value={'GOOG'}>GOOG</MenuItem>
            <MenuItem value={'MSFT'}>MSFT</MenuItem>
            <MenuItem value={'META'}>META</MenuItem>
            <MenuItem value={'NVDA'}>NVDA</MenuItem>
            <MenuItem value={'TSLA'}>TSLA</MenuItem>
            <MenuItem value={'TZA'}>TZA</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={6}>
          <Grid item xs={6}>
            <LineChart
              width={1200}
              height={1200}
              data={dataDisplayLeft}
              margin={{
                top: 5,
                right: 30,
                left: 90,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis dataKey={"Minute"} interval="equidistantPreserveStart" />
              <YAxis yAxisId="right" orientation="right" type="number" domain={['auto', 'auto']} />
              <YAxis yAxisId="left" type="number" domain={['auto', 'auto']} tickFormatter={tick => {return tick.toLocaleString();}} />
              <Tooltip />
              <Legend />
              <Line type="linear" yAxisId="right" dataKey="PriceLast" stroke="#00ff00" dot={false} isAnimationActive={false} />
              <Line type="linear" yAxisId="left" dataKey="AggVol" stroke="#0000ff" dot={false} isAnimationActive={false} />
            </LineChart>
          </Grid>
          <Grid item xs={6}>
            <LineChart
              width={1200}
              height={1200}
              data={dataDisplayRight}
              margin={{
                top: 5,
                right: 30,
                left: 90,
                bottom: 5,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />
              <XAxis dataKey={"Second"} interval="equidistantPreserveStart" />
              <YAxis yAxisId="right" orientation="right" type="number" domain={['auto', 'auto']} />
              <YAxis yAxisId="left" type="number" domain={['auto', 'auto']} tickFormatter={tick => {return tick.toLocaleString();}} />
              <Tooltip />
              <Legend />
              <Line type="linear" yAxisId="right" dataKey="PriceLast" stroke="#00ff00" dot={false} isAnimationActive={false} />
              <Line type="linear" yAxisId="left" dataKey="AggVol" stroke="#0000ff" dot={false} isAnimationActive={false} />
            </LineChart>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
        
export default ContentItemElemGraph;
