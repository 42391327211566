import { Box, TextField } from '@mui/material';

const ContentItemElemEmbedded = (props) => {
    return (
        <Box>
        </Box>
    );
}

export default ContentItemElemEmbedded;
