import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CssBaseline from '@mui/material/CssBaseline';
import App from './App';
import AppContext from './AppContext.mjs';
import Transmitter from './components/Transmitter.js';
import DataPull from './components/DataPull.js';


window.appContext = AppContext;
window.appContext.MenuItems.forEach((itemCur, itemIndex) => {
    itemCur.CrumbStackSegs.push(itemCur);
});
window.appContext.receivedFromServer = (messageIn) => {}
window.appContext.transmitter = new Transmitter();
window.appContext.transmitter.startSessionServer(window.appContext, window.appContext.WebsocketUrl);
if (AppContext.AppId === 'PRC_N') {
  window.appContext.dataPull = new DataPull({});
  //window.appContext.dataPull.start();
  //window.appContext.dataPull = {
  //  secondBuckets: []
  //}
}

const root = ReactDOM.createRoot(document.getElementById('root'));
let rootRendered = false;
const rootRenderAttempt = () => {
  if (window.appContext.transmitter.websocketBEIsActive === true) {
    rootRendered = true;
    root.render(
      <React.StrictMode>
        <CssBaseline />
        <App />
      </React.StrictMode>
    );
  } else {
    setTimeout(() => {
      rootRenderAttempt(); 
    }, 10);
  }
}

rootRenderAttempt(); 


