import './App.css';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Box } from '@mui/material'
import MainPage from './components/MainPage.js';

const theme = createTheme({});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          padding: theme.spacing(5),
          margin: 'auto',
          marginWidth: '30px',
          paddingTop: '0px',
          paddingLeft: '40px',
          paddingRight: '40px',
          paddingBottom: '40px',
          [theme.breakpoints.up("sm")]: {
            width: '600px',
          },
          [theme.breakpoints.up("md")]: {
            width: '900px',
          },
          [theme.breakpoints.up("lg")]: {
            width: '1200px',
          },
          [theme.breakpoints.up("xl")]: {
            width: '100%',
          },
          display: 'flex',
          backgroundColor: '#fbfbfb',
          color: '#285fbf',
        }}
      >
        <MainPage
          theme={theme}
          sx={{
            flexGrow: 1,
            padding: theme.spacing(3),
            transition: theme.transitions.create('margin', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 0,
          }}
        >
        </MainPage>
      </Box>
    </ThemeProvider>
  );
}

export default App;
