import * as React from 'react';
import { Box } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Dropdown, DropdownMenuItem, DropdownNestedMenuItem } from "./Dropdown";
import Tab from '@mui/material/Tab';
import Masthead from './Masthead.js';
import CrumbStack from './CrumbStack.js';
import ContentItemElemGraph from './ContentItemElemGraph.js';
import AppContext from '../AppContext.mjs';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.fat}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date</TableCell>
                                        <TableCell>Customer</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Total price ($)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.history.map((historyRow) => (
                                        <TableRow key={historyRow.date}>
                                            <TableCell component="th" scope="row">
                                                {historyRow.date}
                                            </TableCell>
                                            <TableCell>{historyRow.customerId}</TableCell>
                                            <TableCell align="right">{historyRow.amount}</TableCell>
                                            <TableCell align="right">
                                                {Math.round(historyRow.amount * row.price * 100) / 100}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};
  
const MainPage = (props) => {
    console.log("MainPage - props: ", props);
    const [menuItemPicked, setMenuItemPicked] = React.useState('');
    return (
        <main>
            <Box id="masthead_custom"
                sx={{
                    [props.theme.breakpoints.up("sm")]: {
                        width: '600px',
                    },
                    [props.theme.breakpoints.up("md")]: {
                        width: '900px',
                    },
                    [props.theme.breakpoints.up("lg")]: {
                        width: '1200px',
                    },
                    [props.theme.breakpoints.up("xl")]: {
                        width: '1536px',
                    },
                    marginTop: '0px',
                    padding: '4px',
                    justifyContent: 'space-between',
                    maxHeight: '128px',
                }}
            >
                <Masthead
                    AppContext={window.appContext}
                    RedirectURI={window.appContext.BaseUrl + '/?option=oauthredirect&app_name=Cognito'}
                    LogoutURI={window.appContext.AuthUrl + '/logout?response_type=code&client_id=' +
                        window.appContext.AuthClient + '&scope=openid&redirect_uri=' +
                        window.appContext.BaseUrl + '&state=STATE&scope=openid+profile'}
                />
            </Box>
            <Box id="content_custom"
                sx={{
                    marginTop: '6px',
                    justifyContent: 'space-between',
                }}
            >
                {false /*window.user == null || window.user.data == null || window.user.data.user_login == null*/ ?
                    ''
                    :
                    AppContext.AppId === 'PRC_N' ?
                        <Box>
                            <ContentItemElemGraph
                            />
                        </Box>
                    :
                    AppContext.AppId === 'CNR_N' ?
                    <Box>
                        <Box>
                            <Tab
                                id='Insiders'
                                sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
                                label="Insiders"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setMenuItemPicked(e.target.id)
                                }}
                            />
                        </Box>
                        <Box>
                            {window.appContext.MenuItems.map((menuItemCur) =>
                                menuItemCur.IsTopLevel ? 
                                    <Box>
                                        <CrumbStack
                                            Visible={menuItemCur.Id === menuItemPicked}
                                            CrumbStackSegs={menuItemCur.CrumbStackSegs}
                                            ParentId={null}
                                            Columns={[]}
                                        />
                                    </Box>
                                    :
                                    ''
                            )}
                        </Box>
                    </Box>
                    :
                    AppContext.AppId === 'CPL_N' ?
                    <Box>
                        <Box>
                            <Tab
                                id='CommunitiesActive'
                                sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
                                label="Communities"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setMenuItemPicked(e.target.id)
                                }}
                            />
                        </Box>
                        <Box>
                            {window.appContext.MenuItems.map((menuItemCur) =>
                                menuItemCur.IsTopLevel ? 
                                    <Box>
                                        <CrumbStack
                                            Visible={menuItemCur.Id === menuItemPicked}
                                            CrumbStackSegs={menuItemCur.CrumbStackSegs}
                                            ParentId={null}
                                            Columns={[]}
                                        />
                                    </Box>
                                    :
                                    ''
                            )}
                        </Box>
                    </Box>

                    :
                    ''
                }
            </Box>
        </main>
    );
}

export default React.memo(MainPage);

/*

                    :
                    <Box>
                        <Box>
                            <Tab
                                id='Insiders'
                                sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
                                label="Insiders"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setMenuItemPicked(e.target.id)
                                }}
                            />
                        </Box>
                        <Box>
                            {window.appContext.MenuItems.map((menuItemCur) =>
                                menuItemCur.IsTopLevel ? 
                                    <Box>
                                        <CrumbStack
                                            Visible={menuItemCur.Id === menuItemPicked}
                                            CrumbStackSegs={menuItemCur.CrumbStackSegs}
                                            ParentId={null}
                                            Columns={[]}
                                        />
                                    </Box>
                                    :
                                    ''
                            )}
                        </Box>
                    </Box>


*/



/*
<Box>
<Tab
    id='Flags'
    sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
    label="Flags"
    onClick={(e) => {
        e.preventDefault();
        setMenuItemPicked(e.target.id)
    }}
/>
<Tab
    id='Filers'
    sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
    label="Filers"
    onClick={(e) => {
        e.preventDefault();
        setMenuItemPicked(e.target.id)
    }}
/>
<Tab
    id='Insiders'
    sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
    label="Insiders"
    onClick={(e) => {
        e.preventDefault();
        setMenuItemPicked(e.target.id)
    }}
/>
<Tab
    id='Transactions'
    sx={{ m: '10px', borderRadius: '8px', minHeight: "30px", height: "30px" }}
    label="Transactions"
    onClick={(e) => {
        e.preventDefault();
        setMenuItemPicked(e.target.id)
    }}
/>
</Box>
*/