import * as React from 'react';
import { Box, TextField } from '@mui/material';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import Radio from '@mui/material//Radio';
import RadioGroup from '@mui/material//RadioGroup';
import FormControlLabel from '@mui/material//FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import AppContext from '../AppContext.mjs';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import dayjs, { Dayjs } from 'dayjs';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const ContentItemElemPrimitve = (props) => {
    //console.log("ContentItemElemPrimitve - props: ", props);
    return (
        <Box
            sx={{
                flexDirection: 'row',
                display: props.Format.Visibility == null || props.Format.Visibility !== 'Hidden' ? 'flex' : 'none'
            }}
        >
            <Box
                sx={{
                    marginTop: '8px',
                    display: 'flex',
                    minWidth: '20%'
                }}
                pr={1}
            >
                {props.Format.Label}:
            </Box>
            <Box
                sx={{
                    width: '80%'
                }}
            >
                {props.Format.Mask === 'Text' ?
                    <TextField
                        sx={{
                            width: '100%'
                        }}
                        id={props.Format.Attr}
                        fullwidth={"false"}
                        key={'1'}
                        defaultValue={props.Data}
                        disabled={props.Format.Disabled != null ? props.Format.Disabled : false}
                        InputProps={{ disableUnderline: props.Format.Disabled != null ? props.Format.Disabled : false }}
                        onBlur={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            props.SetItemData({...props.ItemData, [e.target.id]: e.target.value});
                        }}

                    />
                :
                props.Format.Mask === 'Textarea' ?
                    <TextareaAutosize
                        style={{
                            width: '100%'
                        }}
                        id={props.Format.Attr}
                        defaultValue={JSON.stringify(props.Data, null, 2)}
                        disabled={props.Format.Disabled != null ? props.Format.Disabled : false}
                        InputProps={{ disableUnderline: props.Format.Disabled != null ? props.Format.Disabled : false }}
                        onBlur={e => {
                            e.stopPropagation();
                            e.nativeEvent.stopImmediatePropagation();
                            props.SetItemData({...props.ItemData, [e.target.id]: e.target.value});
                        }}
                    />
                :
                props.Format.Mask === 'Radio' ?
                    <FormControl component="fieldset">
                        <RadioGroup
                            row
                            defaultValue={props.Data}
                        >
                            {props.Format.MaskValueSet.map((valueCur, valueIndex) =>
                                <FormControlLabel
                                    value={valueCur}
                                    control={<Radio color="primary" />}
                                    label={valueCur}
                                    labelPlacement="end"
                                />
                            )}
                        </RadioGroup>
                    </FormControl>
                :
                props.Format.Mask === 'Json' ?
                    <Box>
                        <div className="content" dangerouslySetInnerHTML={{ __html: formattedHtml(props.DataAnchor.Row.original[props.Format.Attr]) }}></div>
                    </Box>
                :
                props.Format.Mask === 'File' ?
                    <Box>
                        {props.Data != null && props.Data > '' ?
                            <a target="_blank"
                                href={AppContext.BaseUrl+':'+AppContext.DownloadPort + '?sessionId=' + window.sessionId + '&fileId=' + props.Data}
                                onClick={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }} 
                            >
                                {'Download: ' + props.Data}
                            </a>
                        :
                            ''
                        }
                        <Button
                            style={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                marginLeft: '80px',
                            }}
                            component="label"
                            role={undefined}
                            variant="contained"
                            tabIndex={-1}
                            startIcon={<CloudUploadIcon />}
                        >
                            Upload file
                            <VisuallyHiddenInput type="file" 
                                id={props.Format.Attr}
                                onBlur={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    let filesPicked = [];
                                    for (let i = 0; i < e.target.files.length; i++) {
                                        const formData = new FormData();
                                        let name = e.target.id;
                                        formData.append(name, e.target.files[i]);
                                        filesPicked.push(formData);
                                    }
                                    props.SetItemData({...props.ItemData, [e.target.id]: filesPicked});
                                }} 
                            />
                        </Button>                        
                    </Box>
                :
                props.Format.Mask === 'DateTime' ?
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            style={{
                                width: '100%'
                            }}
                            id={props.Format.Attr}
                            defaultValue={dayjs(props.Data)}
                            disabled={props.Format.Disabled != null ? props.Format.Disabled : false}                                            onBlur={e => {
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                props.SetItemData({...props.ItemData, [e.target.id]: e.target.value});
                            }}
                        />
                    </LocalizationProvider>
                :
                ''
                }
            </Box>
        </Box>
    );
}
        
export default ContentItemElemPrimitve;

const formattedHtml = (rowData) => {
    let Filings = [rowData];

    let outString ='<div>';
    outString += '<table>';

    outString += '<tr>';
    outString += '<td>';
    outString += 'Form/Date/<br>Orig Submission';
    outString += '</td>';
    outString += '<td>';
    outString += 'Transactions';
    outString += '</td>';
    outString += '<td>';
    outString += 'l44 Securities Information';
    outString += '</td>';
    outString += '<td>';
    outString += 'Remarks';
    outString += '</td>';
    outString += '<td>';
    outString += 'Owner Signature';
    outString += '</td>';
    outString += '</tr>';

    Filings.forEach(filingCur => {
        outString += '<tr>';
        outString += '<td>';
        outString += filingCur.DocumentType;
        outString += '<br><br>';
        outString += filingCur.PeriodOfReport;
        outString += '<br>';
        outString += filingCur.DateOfOriginalSubmission ?? '';
        outString += '</td>';

        outString += '<td>';

        outString += '<a href="' + filingCur.AccessionURL + '" target="_blank">SEC Form</a>';

        let formattedTxns = filingCur.FormattedTxns;

        formattedTxns.sort((a, b) => {
            if (a.Date > b.Date) {
                return -1;
            } else {
                if (a.Date < b.Date) {
                    return 1;
                } else {
                    if (a.AcqDisp > b.AcqDisp) {
                        return -1;
                    } else {
                        if (a.AcqDisp < b.AcqDisp) {
                            return 1;
                        } else {
                            if (parseFloat(a.PostTransactionShares) > parseFloat(b.PostTransactionShares)) {
                                return 1;
                            } else {
                                if (parseFloat(a.PostTransactionShares) < parseFloat(b.PostTransactionShares)) {
                                    return -1;
                                } else {
                                    return 0;
                                }
                            }
                        }
                    }
                }
            }
        });

        outString += '<br><table style="border-width: 3px; border-style: solid">';
        outString += '<tr>';

        outString += '<td style="min-width: 150px">';
        outString += 'Share Type';
        outString += '</td>';

        outString += '<td>';
        outString += 'Date';
        outString += '/<br>';
        outString += 'Deemed ExecutionDate';
        outString += '</td>';

        outString += '<td>';
        outString += 'Coding';
        outString += '</td>';

        outString += '<td>';
        outString += 'Timeliness';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Shares';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Price';
        outString += '</td>';

        outString += '<td>';
        outString += 'Acq/<br>Disp';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'After Shares Direct';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'After Shares Indirect';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Post Transaction Value';
        outString += '</td>';

        outString += '<td>';
        outString += 'Ownership Direct or Indirect';
        outString += '</td>';

        outString += '<td style="min-width: 250px">';
        outString += 'Nature Of Ownership';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Exercise Price';
        outString += '</td>';

        outString += '<td>';
        outString += 'Exercise Date';
        outString += '</td>';

        outString += '<td>';
        outString += 'Expiration Date';
        outString += '</td>';

        outString += '<td>';
        outString += 'Underlying Security Title';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Underlying Security Shares';
        outString += '</td>';

        outString += '<td style="text-align: right;">';
        outString += 'Underlying Security Value';
        outString += '</td>';

        outString += '</tr>';

        formattedTxns.forEach(txnCur => {
            outString += '<tr>';

            outString += '<td>';
            outString += txnCur.Title;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.Date;
            outString += '<br>';
            outString += txnCur.DeemedExecutionDate;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.TransactionCode;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.Timeliness;
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.Shares != null && txnCur.Shares > '' ? 
                parseFloat(txnCur.Shares).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.Price;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.AcqDisp;
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.DirectOrIndirectOwnership.substring(0,1) === 'D' && txnCur.PostTransactionShares != null && txnCur.PostTransactionShares > '' ? 
                parseFloat(txnCur.PostTransactionShares).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.DirectOrIndirectOwnership.substring(0,1) === 'D' ? 
                '' : txnCur.PostTransactionShares != null && txnCur.PostTransactionShares > '' ? 
                    parseFloat(txnCur.PostTransactionShares).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.PostTransactionValue != null && txnCur.PostTransactionValue > '' ? 
                parseFloat(txnCur.PostTransactionValue).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.DirectOrIndirectOwnership;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.NatureOfOwnership;
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.ExercisePrice;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.ExerciseDate;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.ExpirationDate;
            outString += '</td>';

            outString += '<td>';
            outString += txnCur.UnderlyingSecurityTitle;
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.UnderlyingSecurityShares != null && txnCur.UnderlyingSecurityShares > '' ? 
                parseFloat(txnCur.UnderlyingSecurityShares).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '<td style="text-align: right;">';
            outString += txnCur.UnderlyingSecurityValue != null && txnCur.UnderlyingSecurityValue > '' ? 
                parseFloat(txnCur.UnderlyingSecurityValue).toLocaleString('en-US', {minimumFractionDigits:0, maximumFractionDigits:0}) : '';
            outString += '</td>';

            outString += '</tr>';

        });
        outString += '</table>';
        outString += '</td>';

        outString += '<td>';
        outString += filingCur.securitiesInformation ?? '';
        outString += '<br><br>';
        outString += filingCur.securitiesToBeSold ?? '';
        outString += '<br><br>';
        outString += filingCur.securitiesInformation ?? '';
        outString += '<br><br>';
        outString += filingCur.securitiesSoldInPast3Months ?? '';
        outString += '</td>';

        outString += '<td>';
        outString += filingCur.remarks ?? '';
        outString += '</td>';

        outString += '<td>';
        outString += filingCur.ownerSignature ?? '';
        outString += '</td>';

        outString += '</tr>';
        
    });
    outString += '</table></div>';
    return outString;
}

/*

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export default function InputFileUpload() {
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<CloudUploadIcon />}
    >
      Upload file
      <VisuallyHiddenInput type="file" />
    </Button>
  );
}
                                        cellCur.Input = document.createElement('input');
                                        cellCur.Input.id = cellCur.Elem.Name;
                                        divField.appendChild(cellCur.Input);
                                        cellCur.Input.setAttribute("type", "file");
                                        cellCur.Input.setAttribute("multiple", "");
                                        cellCur.Input.value = cellCur.Value;
                                        cellCur.Input.style.width = '70%';
                                        cellCur.Input.addEventListener('blur', (event) => {
                                            event.preventDefault();
                                            this.ItemData[event.target.id] = event.target.value;
                                            let labelCur = '';
                                            for (let cur = 0; cur < event.target.files.length; cur++) {
                                                labelCur += event.target.files[cur].name;
                                            };
                                            alert(labelCur);
                                            event.target.appendChild(document.createTextNode(labelCur));
                                        });
                                        if (divField.rendering.Editable != null && divField.rendering.Editable.toLowerCase() === 'no') {
                                            cellCur.Input.disabled = true;
                                        }
*/

/*                        props.Format.Mask === 'Json' ?
                            <TextareaAutosize
                                style={{
                                    width: '100%'
                                }}
                                id={props.Format.Label}
                                defaultValue={JSON.stringify(props.Data, null, 2)}
                                disabled={props.Format.Disabled != null ? props.Format.Disabled : false}
                                InputProps={{ disableUnderline: props.Format.Disabled != null ? props.Format.Disabled : false }}
                                onBlur={e => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                }}
                            />
                        :

*/